import Company from './Company';
import User from './User';
import Dashboard from './Dashboard';
import Permissions from './Permissions';

const Registers = {
  Company,
  User,
  Dashboard,
  Permissions,
};

export default Registers;
