const theme = {
  background: '#F7F7FC',
  text: '#11142D',
  primaryBackground: '#5541D7',
  primaryText: '#F7F7FC',
  secondaryBackground: 'lightgray',
  secondaryText: 'gray',
};

export default theme;
